var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      _c("ComponentDeveloperUploadAccountType"),
      _c("ComponentDeveloperAccountTypeExcelList"),
      _c("ComponentDeveloperAccountTypeDatabaseList"),
      _c("ComponentDeveloperBonusOfferUnmatched"),
      _c("v-row", { staticClass: "ma-2" }, [
        _c("span", { staticStyle: { "font-size": "20px" } }, [
          _vm._v(" Below is the Matched and Unmatched Account Type List "),
        ]),
      ]),
      _c("ComponentDeveloperAccountTypeMatched"),
      _c("ComponentDeveloperAccountTypeUnmatched"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }